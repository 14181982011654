window.require = require
window.jQuery = window.$ = require('jquery')
var _ = require('underscore')

if ('CSS' in window && 'supports' in window.CSS) {
	if (window.CSS.supports('mix-blend-mode', 'saturation')) {
		$('body').addClass('blend-device')
	}
}

var Tripartite = require('tripartite')
/*
var templates = require('../static_content/templates/pages.tmpl')
Tripartite.parseTemplateScript(templates)
*/

var shiner = require('shiner/shiner-no-jquery')(jQuery)




require('page-editor').configure()

$('.faq .question').click(function (evt) {
	$(this).closest('.faq').toggleClass('open')
})

$('.menu-bar .close').click(function (evt) {
	evt.preventDefault()
	$('body').removeClass('show-menu')
})

$('.logo-bar .tribar').click(function (evt) {
	evt.preventDefault()
	$('body').addClass('show-menu')
})

var media = null;

var close = function () {
	if (media) {
		media.find('video').get(0).pause();
		media.remove();
		media = null;
	}
	// $('.big-detailed-img').remove();
};

$('.video-story, .top-video').on('click', function (evt) {
	evt.preventDefault();
	var urlPart = $(this).attr('data-video');
	if(urlPart) {
		media = $('<div class="movie-container no-show"><div class="movie-box"><video poster="/vrsc/123/img/white-poster.jpg" \
				controls="controls" preload="none" autoplay="autoplay">\
			<!-- MP4 for Safari, IE9, iPhone, iPad, Android, and Windows Phone 7 -->\
			<source type="video/mp4" src="' + urlPart + '.mp4" />\
			<!-- WebM/VP8 for Firefox4, Opera, and Chrome -->\
			<source type="video/webm" src="' + urlPart + '.webm" />\
			<!-- Ogg/Vorbis for older Firefox and Opera versions -->\
			<source type="video/ogg" src="' + urlPart + '.ogv" />\
			</video><div class="close">close</div></div></div>');

		$('body').append(media);
		var player = media.find('video').get(0);
		var movieBox = media.find('.movie-box');
		//      setTimeout(function() {
		//          var videoViewportWidth = (player.videoWidth / player.videoHeight) * movieBox.height();
		//          var rightOffset = ((movieBox.width() - videoViewportWidth) / 2) + 15;
		//          media.find('.close').css('right', rightOffset + 'px').show();
		//      }, 1000);

		media.on('click', '.close', function (evt) {
			close();
		});

		setTimeout(function () {
			media.removeClass('no-show');
		}, 1);
	}
	else {
		urlPart = $(this).attr('data-link');
		if(urlPart) {
			window.location = urlPart
		}
	}


});

$('body').keydown(function (evt) {
	if (evt.keyCode == 27) {
		close();
	}
});

$('.panel-selector a').click(function (evt) {
	evt.preventDefault()
	var panelName = $(this).attr('data-panel')
	$('.panels .box-links').removeClass('current')
	$('.panels .box-links[data-panel=' + panelName + ']').addClass('current')
	$('.panel-selector a').removeClass('current')
	$(this).addClass('current')
})

$('form.search button').click(function (evt) {
	if (!$('form.search').hasClass('expanded')) {
		evt.preventDefault()
		var containerWidth = $(this).closest('.container').width()
		var labelWidth = $('form.search strong').width()
		var logoWidth = $('.logo-box').width()
		var partsHolderWidth = $(this).closest('.parts-holder').width()
		$('form.search input').width(labelWidth)
		$('form.search strong').hide()
		$('form.search').addClass('expanded')
		var newWidth = (containerWidth - partsHolderWidth - logoWidth - 9) + labelWidth
		setTimeout(function () {
			$('form.search input').width(newWidth)
		})

	}
	else {
		var form = $('form.search')
		var input = $('form.search input')
		var val = input.val()
		if (!val) {
			evt.preventDefault()
			$('form.search').removeClass('expanded')
			$('form.search input').css('width', '')
			$('form.search strong').show()
		}
		else {
			form.attr('action', form.attr('action') + val)
			input.attr('name', '')
			evt.preventDefault()
			window.location.href = form.attr('action')
		}
	}
})

$('.blog-posts-page').each(function () {
	var cols = []
	var one = []
	var two = []
	var three = []
	cols.push(one)
	cols.push(two)
	cols.push(three)

	$('.post').each(function () {
		var col = cols.shift()
		col.push(this)
		$(this).removeClass('third')
		cols.push(col)
	})

	cols = []
	cols.push(one)
	cols.push(two)
	cols.push(three)

	$('.grid-row').empty()

	_.each(cols, function (col) {
		var hcol = $('.grid-row').append('<div class="third"></div>').find('.third').last()
		_.each(col, function (post) {
			$(hcol).append(post)
		})
	})
})


$('.subscribe [name=email]').focus(function (evt) {
	if ($('.subscribe [name=vrf]').length == 0) {
		$('.subscribe form').append('<input name="vrf" required="required" type="text" placeholder="Human check! What\'s 5 + 7?"/>')
	}
})

$('.subscribe form').submit(function (evt) {
	if ($('.subscribe [name=vrf]').val() != 12) {
		$('.subscribe [name=vrf]').val('').addClass('error')
		evt.preventDefault()
	}
})

$('*[data-js-link]').click(function (evt) {
	window.location = $(this).attr('data-js-link')
})


$(function () {
	var total = $('.shiner-show .slide').length

	if (total > 1) {
		$('.shiner-show .slide').addClass('invisible').eq(0).removeClass('invisible').addClass('visible')
		$('.shiner-show').addClass('transitions-on')
		
		var slideCount = $('.shiner-show .slide').length

		var options = {}
		if (window.shinerDelay) {
			options.delay = window.shinerDelay
		}

		var shine = $('.shiner-show .slide').shiner(options)
		window.shine = shine


		$('.shiner-show .advance').on('click', function (evt) {
			shine.next()
		})
		$('.shiner-show .previous').on('click', function (evt) {
			shine.previous()
		})

		shine.onVisible = function (slide, ind) {
			var $placeOf = $('.shiner-show .place-of')
			if ($placeOf.length > 0) {
				$placeOf.html((ind + 1) + ' of ' + total)
			}

			var $theShow = $(slide).closest('.shiner-show')
			$theShow.find('.dot').removeClass('current').eq(ind).addClass('current')
		}

		var $dots = $('.shiner-show .dots')
		if ($dots.length > 0) {
			for (var i = 0; i < slideCount; i++) {
				$dots.append('<div class="dot"></div>')
			}
		}
		$('.shiner-show .dot').eq(0).addClass('current')
		$('.shiner-show .dot').on('click', function(evt) {
			var ind = $('.shiner-show .dot').index(this)
			shine.showSlide(ind)

		})

		var container = $('.shiner-show').get(0)
		try {
			window.shinerSwipeListener = SwipeListener(container)
			container.addEventListener('swipe', function (e) {
				var directions = e.detail.directions
				var x = e.detail.x
				var y = e.detail.y

				if (directions.left) {
					shine.next()
				}
				if (directions.right) {
					shine.previous()
				}
			})
		}
		catch (e) {

		}


	}
	else {
		$('.shiner-show .slide').removeClass('invisible').addClass('visible')
		$('.shiner-show').addClass('transitions-on')
	}
})